import { ReactComponentManifest } from "web/entrypoints/common/setup";
import AppFooter from "web/react/components/app-footer/app-footer";
import BannerContainer from "web/react/components/banner-container/banner-container";
import { Breadcrumbs } from "web/react/components/breadcrumbs/breadcrumbs";
import { CustomerCaptureOverlay } from "web/react/components/customer-capture-overlay/customer-capture-overlay";
import Example from "web/react/components/example/example";
import { FacebookCampaignControl } from "web/react/components/facebook-campaign-control/facebook-campaign-control";
import JSONLDOrganization from "web/react/components/json-ld/json-ld-organization";
import { Navigation } from "web/react/components/navigation";
import { RelatedProductsShowMore } from "web/react/components/related-products-show-more/related-products-show-more";
import TopBanner from "web/react/components/top-banner/top-banner";
import { InitializedToastProvider } from "web/react/emo/toast/toast-provider";
import ChangePassword from "web/react/pages/account/change-password/change-password";
import FollowedLystsPage from "web/react/pages/account/followed-lysts";
import PasswordResetComplete from "web/react/pages/account/reset-password-pages/password-reset-complete";
import PasswordResetLinkFailedPage from "web/react/pages/account/reset-password-pages/reset-password-failed-page";
import ResetPasswordPage from "web/react/pages/account/reset-password-pages/reset-password-page";
import AccountSettings from "web/react/pages/account/settings/account-settings";
import SignupOrLoginPage from "web/react/pages/account/signup-or-login-page";
import ToAppPage from "web/react/pages/app/to-app-page";
import { BrandsPage } from "web/react/pages/brands";
import DiscoveryContentPage from "web/react/pages/discovery/discovery-content";
import withRedux from "web/react/redux-provider";

/*
    Common components that are on every page (eg header)
*/
const components: ReactComponentManifest = {
    ToAppPage,
    BannerContainer: withRedux(BannerContainer),
    Breadcrumbs,
    CustomerCaptureOverlay: withRedux(CustomerCaptureOverlay),
    DiscoveryContentPage,
    Example,
    JSONLDOrganization,
    AppFooter,
    RelatedProductsShowMore,
    TopBanner,
    PasswordResetComplete,
    SignupOrLoginPage: withRedux(SignupOrLoginPage),
    InitializedToastProvider: withRedux(InitializedToastProvider),
    FollowedLystsPage,
    ResetPasswordPage,
    PasswordResetLinkFailedPage,
    Navigation: withRedux(Navigation),
    BrandsPage,
    FacebookCampaignControl,
    AccountSettings,
    ChangePassword,
};

export default components;
