import React, { useEffect, useState } from "react";
import { useClickTracker } from "web/react/hooks/use-click-tracker/use-click-tracker";
import { useSaveForLater } from "web/react/hooks/use-save-for-later/use-save-for-later";
import analytics from "web/script/analytics/analytics";
import globals from "web/script/modules/globals";
import userProfiler from "web/script/modules/userprofiler";
import { redirectInNewTab } from "web/script/utils/url";

function constructHref(
    href: string,
    pageViewID?: string,
    reason?: string,
    type?: string,
    fromUrl?: string
): string {
    let parsed = new URL(href, globals.window.location.href);

    if (pageViewID) {
        parsed.searchParams.set("pageViewId", pageViewID);
    }
    if (reason) {
        parsed.searchParams.set("reason", reason);
    }
    if (type) {
        parsed.searchParams.set("type", type);
    }
    if (fromUrl) {
        parsed.searchParams.set("fromUrl", fromUrl);
    }

    return parsed.toString();
}

/**
 * A custom hook for keeping a lead URL up to date with analytics data
 * @param {string} href The lead URL to track
 * @param {string} [reason] Optional lead reason
 * @param {string} [type] Optional lead type
 * @param {string} [fromUrl] Optional origin URL
 * @returns {string} The augmented lead URL
 */
export function useLeadHref(
    href: string,
    reason?: string,
    type?: string,
    fromUrl?: string
): string {
    const [leadHref, setLeadHref] = useState<string>(href);

    useEffect(() => {
        function updateHref(): void {
            let pageViewID = analytics.getPageViewId();
            setLeadHref(constructHref(href, pageViewID, reason, type, fromUrl));
        }
        updateHref();
        analytics.on("pageViewIdChanged", updateHref);

        return function cleanup(): void {
            analytics.off("pageViewIdChanged", updateHref);
        };
    }, [fromUrl, href, reason, type]);

    return leadHref;
}

interface LeadLinkClickProps {
    href: string;
    reason: string;
    beforeOnClick?: (
        processClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void,
        event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
    ) => void;
    afterOnClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
    productId?: string;
    openInNewTab?: boolean;
    eventCategory?: string;
    eventAction?: string;
    eventLabel?: string;
    type?: string;
    fromUrl?: string;
}

interface LeadLinkClick {
    onClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
    leadHref: string;
}

export function useLeadLinkClick({
    href,
    reason,
    type,
    fromUrl,
    beforeOnClick,
    productId,
    openInNewTab,
    eventCategory,
    eventAction,
    eventLabel,
    afterOnClick,
}: LeadLinkClickProps): LeadLinkClick {
    const pendingPromises = React.useRef<Promise<void>[] | any[]>([]);
    let { isSaved } = useSaveForLater(productId as string);
    let leadHref = useLeadHref(href, reason, type, fromUrl);
    let savedForLaterClickTracker = useClickTracker(
        "affiliate",
        "lead",
        "save_for_later_buy_on_store",
        !openInNewTab
    );
    let trackOnClick = useClickTracker(eventCategory, eventAction, eventLabel, !openInNewTab);

    function processClick(event): void {
        event.preventDefault();

        if (productId && reason) {
            userProfiler.saveClickedLead(productId, reason);
        }

        if (isSaved) {
            pendingPromises.current.push(savedForLaterClickTracker(event));
        }

        if (trackOnClick) {
            pendingPromises.current.push(trackOnClick(event));
        }

        if (!openInNewTab) {
            Promise.all(pendingPromises.current).then(() => {
                globals.window.location.replace(leadHref);
            });
        } else {
            redirectInNewTab(leadHref, "redirect-to-retailer");
        }

        afterOnClick?.(event);
    }

    function onClick(event): void {
        if (beforeOnClick) {
            beforeOnClick(processClick, event);
        } else {
            processClick(event);
        }
    }

    return {
        onClick,
        leadHref,
    };
}
