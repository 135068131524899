import { CardNumberElement, useElements, useStripe } from "@stripe/react-stripe-js";
import {
    Card,
    PaymentMethod,
    Stripe,
    StripeCardNumberElement,
    StripeError,
} from "@stripe/stripe-js";
import noop from "lodash/noop";
import React, { createContext, useEffect, useState } from "react";
import { useDomViewport } from "web/react/hooks/use-dom-viewport/use-dom-viewport";
import analytics from "web/script/analytics/analytics";
import environment from "web/script/modules/environment";
import { _maxAttempts, callEndpoint, callPollEndpoint } from "web/script/utils/call-poll-endpoint";
import { buildLanguageAwareUrlPath } from "web/script/utils/url";
import uuid4 from "web/script/utils/uuid";
import { Address } from "web/types/address";
import { BillingShippingInformation } from "web/types/customer-information";
import { FieldData } from "web/types/form";
import {
    CheckoutCostSummarySerializer,
    CheckoutErrorSerializer,
    CheckoutLayoutSerializer,
    CheckoutPaymentInformationSerializer,
    CheckoutProductSerializer,
    CheckoutShippingOptionSerializer,
} from "web/types/serializers";
import useDeliveryForm, {
    CHECKOUT_CONTEXT_DELIVERY_FORM_DEFAULT_DATA,
    CheckoutContextDeliveryForm,
} from "./hooks/use-delivery-form";
import usePaymentForm, {
    CHECKOUT_CONTEXT_PAYMENT_FORM_DEFAULT_DATA,
    CheckoutContextPaymentForm,
    convertPaymentInformation,
    normalizeCardBrand,
} from "./hooks/use-payment-form";
import useShippingBillingForm, {
    CHECKOUT_CONTEXT_SHIPPING_FORM_DEFAULT_DATA,
    CheckoutContextShippingForm,
    convertBillingShippingInformation,
    convertBillingShippingInformationToSerializer,
} from "./hooks/use-shipping-billing-form";
import useThreeDsChallenge from "./hooks/use-three-ds-challenge/use-three-ds-challenge";
import useViewsNavigation, {
    CHECKOUT_CONTEXT_VIEWS_NAVIGATION_DEFAULT_DATA,
    CheckoutContextViewsNavigation,
    CheckoutView,
    VIEW_TITLES,
} from "./hooks/use-views-navigation";

const CHECKOUT_SHIPPING_ENDPOINT = "/checkout/shipping/";
const CHECKOUT_DELIVERY_ENDPOINT = "/checkout/delivery/";
const CHECKOUT_PAYMENT_ENDPOINT = "/checkout/payment/";
const CHECKOUT_PURCHASE_ENDPOINT = "/checkout/purchase/";
const CHECKOUT_USER_HAS_SEEN_ERROR_ENDPOINT = "/checkout/user-has-seen-error/";

enum CheckoutContextRequestErrorType {
    SHIPPING_FORM = "shipping_form",
    DELIVERY_METHOD = "delivery_method",
    PAYMENT_METHOD = "payment_method",
    BUY_NOW = "purchase_attempt",
    THREE_DS_CHALLENGE = "3DS_challenge",
    PURCHASE_OUTCOME = "purchase_outcome",
    MARKETING_PREFERENCE = "marketing_preference",
}

// https://git.lystit.com/lyst/shopping-cart-frontend/blob/8abe89ec0e3c31010668f570c0cdb5d760ec2997/src/shopping_cart_frontend/constants.py#L162
enum CheckoutRequestErrorCode {
    GENERIC_ERROR = "generic_error",
    INVALID_ADDRESS = "invalid_address",
    SHIPPING_ADDRESS_REFUSED = "shipping_address_refused",
    BAD_DOMAIN = "bad_domain",
    PAYMENT_REFUSED = "payment_refused",
    UNSUPPORTED_CARD = "unsupported_card",
}

// https://git.lystit.com/lyst/shopping-cart-frontend/blob/8abe89ec0e3c31010668f570c0cdb5d760ec2997/src/shopping_cart_frontend/constants.py#L58
export enum CheckoutOrderState {
    NEW = "new",
    ADDRESS_ERROR = "address_error",
    SHIPPING = "shipping_details_set",
    OPTIONS_ERRORS = "options_errors",
    DELIVERY = "delivery_option_selected",
    PAYMENT_ERRORS = "payment_errors",
    PAYMENT = "payment_details_entered",
    THREE_DS_CHALLENGED = "3ds_challenged",
    PENDING = "pending",
    FULFILLED = "fulfilled",
    FAILED = "failed",
}

// https://git.lystit.com/lyst/shopping-cart/blob/6d90c938485c78a2befaac3a115dc56ef006377c/src/shopping_cart/constants/payment.py#L10
export enum PaymentProvider {
    STRIPE_TOKEN = "stripe_token",
    STRIPE_PAYMENT_INTENT = "stripe_payment_intent",
}

// https://git.lystit.com/lyst/shopping-cart/blob/6d90c938485c78a2befaac3a115dc56ef006377c/src/shopping_cart/constants/payment.py#L4
export enum PaymentTypes {
    CARD = "card",
    GOOGLE_PAY = "google_pay",
    APPLE_PAY = "apple_pay",
}

// https://git.lystit.com/lyst/lyst-data-dictionary-schemas/blob/5d3339b9e9ff91d9684a0170e1b2b2c43e072e81/schemas/abstract/purchase_intent_session_v1_0.json#L30
export enum PurchaseIntentCheckoutType {
    NATIVE = "native",
    OLD_CHECKOUT = "old_checkout",
    APPLE_PAY = "applePay",
    GOOGLE_PAY = "googlePay",
}

// https://git.lystit.com/lyst/lyst-data-dictionary-schemas/blob/5d3339b9e9ff91d9684a0170e1b2b2c43e072e81/schemas/abstract/purchase_intent_session_v1_0.json#L25
export enum PurchaseIntentPurchaseType {
    CHECKOUT = "checkout",
    AFFILIATE = "affiliate",
}

const CheckoutPaymentProviderIdKeys = {
    [PaymentProvider.STRIPE_TOKEN]: "token_id",
    [PaymentProvider.STRIPE_PAYMENT_INTENT]: "payment_method_id",
};

interface CheckoutContextInitialDataProps extends CheckoutLayoutSerializer {
    pageTitle: string;
    checkoutOrderId: string | null;
    purchaseIntentSessionId: string;
}

interface CheckoutContextProps {
    country: string;
    products: CheckoutProductSerializer[];
    costSummary: CheckoutCostSummarySerializer;
    deliveryForm: CheckoutContextDeliveryForm;
    customerEmail: FieldData;
    marketingPreferences: {
        isMarketingPreferenceChecked: boolean;
        setMarketingPreferenceCheck: (preference: boolean) => void;
        showMarketingPreferences: boolean;
        optInMarketingText: string;
    };
    paymentForm: CheckoutContextPaymentForm;
    shippingForm: CheckoutContextShippingForm;
    purchaseOutcome: {
        orderNumber: string | null;
        isOrderSuccessful: boolean | null;
        isOrderPending: boolean;
        selectedShipping: CheckoutShippingOptionSerializer | null;
    };
    onToggleAccordion: (accordion: CheckoutView) => void;
    onClickBuyNowCTA: () => void;
    isBuyNowEnabled: boolean;
    showFullPageLoadingSpinner: boolean;
    threeDsChallengedModal: {
        isThreeDsChallenged: boolean;
        threeDsUrl: string;
        resetThreeDsChallenged: () => void;
    };
    errorModal: {
        isErrorModalOpen: boolean;
        setErrorModal: (openModal: boolean) => void;
        errorTitle: string;
        errorDescription: string;
        errorCTAText: string;
    };
    views: CheckoutContextViewsNavigation;
}

interface StripePaymentReturn {
    id: string;
    card: Card | PaymentMethod.Card;
    error: StripeError;
}

async function createStripeToken(
    stripe: Stripe,
    cardNumberElement: StripeCardNumberElement,
    billingAddress: Address
): Promise<Partial<StripePaymentReturn>> {
    const tokenResponse = await stripe.createToken(cardNumberElement, {
        address_line1: billingAddress.addressLine1.value,
        address_line2: billingAddress.addressLine2.value,
        address_city: billingAddress.city.value,
        address_state: billingAddress.region.value,
        address_zip: billingAddress.zipCode.value,
        address_country: billingAddress.countryCode.value,
    });

    return {
        id: tokenResponse.token?.id,
        card: tokenResponse.token?.card,
        error: tokenResponse.error,
    };
}

async function createStripePaymentMethod(
    stripe: Stripe,
    cardNumberElement: StripeCardNumberElement,
    billingInformation: BillingShippingInformation,
    email: string,
    name: string
): Promise<Partial<StripePaymentReturn>> {
    const billingAddress = billingInformation.address;

    const paymentMethodResponse = await stripe.createPaymentMethod({
        type: "card",
        card: cardNumberElement,
        billing_details: {
            name,
            address: {
                line1: billingAddress.addressLine1.value,
                line2: billingAddress.addressLine2.value,
                city: billingAddress.city.value,
                state: billingAddress.region.value,
                postal_code: billingAddress.zipCode.value,
                country: billingAddress.countryCode.value,
            },
            email,
            phone: billingInformation.phoneNumber.value,
        },
    });

    return {
        id: paymentMethodResponse.paymentMethod?.id,
        card: paymentMethodResponse.paymentMethod?.card,
        error: paymentMethodResponse.error,
    };
}

export const CheckoutContext = createContext<CheckoutContextProps>({
    country: "",
    products: [],
    costSummary: {
        net_cost: {
            amount: "",
            amount_with_currency: "",
            currency_code: "",
            is_estimated: false,
        },
        shipping_cost: {
            amount: "",
            amount_with_currency: "",
            currency_code: "",
            is_estimated: false,
        },
        total_cost: {
            amount: "",
            amount_with_currency: "",
            currency_code: "",
            is_estimated: false,
        },
        duties_and_taxes: {
            amount: "",
            amount_with_currency: "",
            currency_code: "",
            is_estimated: false,
        },
    },
    customerEmail: {
        error: "",
        value: "",
    },
    deliveryForm: CHECKOUT_CONTEXT_DELIVERY_FORM_DEFAULT_DATA,
    marketingPreferences: {
        isMarketingPreferenceChecked: false,
        setMarketingPreferenceCheck: noop,
        showMarketingPreferences: false,
        optInMarketingText: "",
    },
    paymentForm: CHECKOUT_CONTEXT_PAYMENT_FORM_DEFAULT_DATA,
    shippingForm: CHECKOUT_CONTEXT_SHIPPING_FORM_DEFAULT_DATA,
    purchaseOutcome: {
        orderNumber: "",
        isOrderSuccessful: false,
        isOrderPending: false,
        selectedShipping: null,
    },
    onToggleAccordion: noop,
    onClickBuyNowCTA: noop,
    isBuyNowEnabled: true,
    showFullPageLoadingSpinner: false,
    threeDsChallengedModal: {
        isThreeDsChallenged: false,
        threeDsUrl: "",
        resetThreeDsChallenged: noop,
    },
    errorModal: {
        isErrorModalOpen: false,
        setErrorModal: noop,
        errorTitle: "",
        errorDescription: "",
        errorCTAText: "",
    },
    views: CHECKOUT_CONTEXT_VIEWS_NAVIGATION_DEFAULT_DATA,
});

interface CheckoutContextProviderProps {
    children: React.ReactNode;
    initialData: CheckoutContextInitialDataProps;
    onPaymentContextChange: (data: CheckoutPaymentInformationSerializer) => void;
    paymentProvider?: string | null;
}

const CheckoutContextProvider = ({
    children,
    initialData,
    onPaymentContextChange,
    paymentProvider,
}: CheckoutContextProviderProps): React.ReactElement => {
    const elements = useElements();
    const stripe = useStripe();

    const [products, updateProducts] = useState<CheckoutProductSerializer[]>(initialData.products);
    const [costSummary, updateCostSummary] = useState<CheckoutCostSummarySerializer>(
        initialData.cost_summary
    );
    const [checkoutError, setCheckoutError] = useState<CheckoutErrorSerializer | null>(
        initialData.error
    );
    const [isErrorModalOpen, setErrorModal] = useState<boolean>(false);

    const [checkoutOrderState, setCheckoutOrderState] = useState<string>(initialData.local_state);
    const [checkoutOrderId, setCheckoutOrderId] = useState<string | null>(
        initialData.checkoutOrderId
    );
    const purchaseIntentSessionId = initialData.purchaseIntentSessionId;

    const {
        billingInformation,
        customerEmail,
        isShippingFormComplete,
        hasShippingFormErrored,
        onBillingInformationChange,
        onCustomerEmailChange,
        onShippingInformationChange,
        setShippingFormComplete,
        setShippingFormErrored,
        setShippingLoadingSpinner,
        shippingInformation,
        showShippingLoadingSpinner,
        sendShippingFormChangedAnalytics,
        validateShippingBillingForm,
    } = useShippingBillingForm({
        initialData,
    });

    const {
        isDeliveryFormComplete,
        hasDeliveryFormErrored,
        onShippingMethodsChange,
        onDeliverySelect,
        savedShipping,
        selectedShipping,
        setDeliveryFormComplete,
        setDeliveryFormErrored,
        setDeliveryLoadingSpinner,
        setSavedShipping,
        showDeliveryLoadingSpinner,
        shippingMethods,
    } = useDeliveryForm({ initialData });

    const {
        isPaymentFormComplete,
        hasPaymentFormErrored,
        onPaymentInformationChange,
        paymentFormData,
        paymentFormName,
        setPaymentFormComplete,
        setPaymentFormErrored,
        setPaymentLoadingSpinner,
        setPaymentFormName,
        showPaymentLoadingSpinner,
    } = usePaymentForm({
        initialData,
    });

    const {
        activeView,
        isBuyNowEnabled,
        isShippingFormDisabled,
        isDeliveryFormDisabled,
        isPaymentFormDisabled,
        pageTitle,
        goToLatestStep,
        goToView,
        setFullPageLoadingSpinner,
        showFullPageLoadingSpinner,
        onClickBackButton,
        onToggleAccordion,
        exitCheckoutPage,
        stayInCheckout,
        triggerCheckoutExit,
        showCheckoutExitPrompt,
    } = useViewsNavigation({
        products,
        isPaymentFormComplete,
        isDeliveryFormComplete,
        isErrorModalOpen,
        isShippingFormComplete,
        showShippingLoadingSpinner,
        showDeliveryLoadingSpinner,
        showPaymentLoadingSpinner,
        checkoutOrderId,
        checkoutOrderState,
        purchaseIntentSessionId,
        hasShippingFormErrored,
        hasDeliveryFormErrored,
        hasPaymentFormErrored,
    });

    const {
        isThreeDsChallenged,
        threeDsUrl,
        setThreeDsChallenged,
        setThreeDsUrl,
        resetThreeDsChallenged,
    } = useThreeDsChallenge();

    const country = environment.get("country");
    const showMarketingPreferences = initialData.marketing_preference.show_opt_in_marketing_text;
    const optInMarketingText = initialData.marketing_preference.opt_in_marketing_text;
    const [isMarketingPreferenceChecked, setMarketingPreferenceCheck] = useState<boolean>(
        initialData.marketing_preference.is_marketing_preference_enabled
    );

    const [orderNumber, setOrderNumber] = useState<string | null>(initialData.order_number || null);
    const [isOrderSuccessful, setOrderSuccessful] = useState<boolean>(false);
    const [isOrderPending, setOrderPending] = useState<boolean>(false);

    const [errorTitle, setErrorTitle] = useState<string>("");
    const [errorDescription, setErrorDescription] = useState<string>("");
    const [errorCTAText, setErrorCTAText] = useState<string>("");

    const { isMobileViewport } = useDomViewport();

    useEffect(() => {
        if (!checkoutError) {
            setShippingFormErrored(false);
            setPaymentFormErrored(false);
            return;
        }

        if (
            checkoutError.code === CheckoutRequestErrorCode.INVALID_ADDRESS ||
            checkoutError.code === CheckoutRequestErrorCode.SHIPPING_ADDRESS_REFUSED ||
            checkoutError.code === CheckoutRequestErrorCode.BAD_DOMAIN
        ) {
            setShippingFormErrored(true);
        } else if (checkoutError.code === CheckoutRequestErrorCode.PAYMENT_REFUSED) {
            setPaymentFormErrored(true);
        }
    }, [checkoutError, setShippingFormErrored, setPaymentFormErrored]);

    function onProductsChange(products: CheckoutProductSerializer[]): void {
        updateProducts(products);
    }

    function onCostSummaryChange(costSummary: CheckoutCostSummarySerializer): void {
        updateCostSummary(costSummary);
    }

    function onOrderNumberChange(orderNumber: string | null): void {
        setOrderNumber(orderNumber);
    }

    function openErrorModal(title: string, description: string, ctaText: string): void {
        setErrorTitle(title);
        setErrorDescription(description);
        setErrorCTAText(ctaText);
        setErrorModal(true);
    }

    function onFrontendError(
        errorCode: CheckoutRequestErrorCode,
        errorType: CheckoutContextRequestErrorType,
        errorLogMessage = ""
    ): void {
        setShippingLoadingSpinner(false);
        setDeliveryLoadingSpinner(false);
        setPaymentLoadingSpinner(false);
        setFullPageLoadingSpinner(false);

        const { errorTitle, errorMessage, errorCta } = generateErrorDescription(
            errorCode,
            errorType
        );

        const category = errorType;
        const action = "errored";
        const label = errorCode;
        const subType = `${category}.${action}`;
        const itemData = errorLogMessage
            ? [
                  {
                      item_type: "error",
                      id: uuid4.uuid4(),
                      error_type: errorCode,
                      error_msg: errorLogMessage,
                  },
              ]
            : [];
        analytics.event(category, action, label, false, null, subType, {}, itemData);

        openErrorModal(errorTitle, errorMessage, errorCta);
    }

    function generateErrorDescription(
        errorCode: CheckoutRequestErrorCode,
        errorType: CheckoutContextRequestErrorType
    ): { errorTitle: string; errorMessage: string; errorCta: string } {
        let errorTitle = "Something's gone wrong";
        let errorMessage;
        let errorCta = "Close";

        switch (errorCode) {
            case CheckoutRequestErrorCode.INVALID_ADDRESS:
            case CheckoutRequestErrorCode.BAD_DOMAIN:
                errorMessage =
                    "We could not recognize this as a valid address." +
                    "\n\nPlease check it again or try a different one.";
                break;
            case CheckoutRequestErrorCode.SHIPPING_ADDRESS_REFUSED:
                errorMessage =
                    "The retailer does not ship to this address." +
                    "\n\nPlease try a different one.";
                break;
            case CheckoutRequestErrorCode.PAYMENT_REFUSED:
                errorTitle = "Your payment didn't go through";
                errorMessage =
                    "Please review your details or try another payment method. " +
                    "If you continue to see the error, please contact your bank." +
                    "\n\nYou have not been charged for this transaction.";
                break;
            case CheckoutRequestErrorCode.UNSUPPORTED_CARD:
                errorTitle = "Your card is not supported";
                errorMessage =
                    " We're sorry, but your payment card is not supported. " +
                    "Please try a different payment method." +
                    "\n\nYou have not been charged for this transaction.";
                break;
            case CheckoutRequestErrorCode.GENERIC_ERROR:
                if (errorType === CheckoutContextRequestErrorType.PAYMENT_METHOD) {
                    errorMessage =
                        "We're sorry, but it looks like something's gone wrong. Please try again. " +
                        "\n\nYou have not been charged at this point.";
                    break;
                }
                if (errorType === CheckoutContextRequestErrorType.BUY_NOW) {
                    errorTitle = "Your payment didn't go through";
                    errorMessage =
                        "We're sorry, but it looks like something's gone wrong during the payment attempt. " +
                        "Please try again." +
                        "\n\nYou have not been charged for this transaction.";
                    break;
                }
            default:
                errorMessage =
                    "We're sorry, but it looks like something's gone wrong." +
                    "\n\nPlease try again later.";
                break;
        }

        return { errorTitle, errorMessage, errorCta };
    }

    function rehydrateUi(response: any): void {
        // This will rehydrate the UI with the latest information
        const layoutResponse: CheckoutLayoutSerializer = response.data.checkout.checkout_layout;
        const products: CheckoutProductSerializer[] = layoutResponse.products;
        onProductsChange(products);
        onCostSummaryChange(layoutResponse.cost_summary);
        onShippingMethodsChange(products[0].shipping_methods);
        setSavedShipping(products[0].shipping_methods.find((method) => method.is_selected) || null);

        onBillingInformationChange(
            convertBillingShippingInformation(layoutResponse.billing_information)
        );
        onShippingInformationChange(
            convertBillingShippingInformation(layoutResponse.shipping_information)
        );
        onCustomerEmailChange({
            value: layoutResponse.email || "",
            error: "",
        });
        onPaymentInformationChange(convertPaymentInformation(layoutResponse.payment_information));
        onOrderNumberChange(layoutResponse.order_number || null);

        const paymentContextData = layoutResponse.payment_information;
        onPaymentContextChange(paymentContextData);

        setCheckoutOrderState(layoutResponse.local_state);
        setCheckoutOrderId(response.data.checkout.checkout_order_id);

        // To prevent unnecessary re-rendering we should keep the order of the following calls
        setPaymentFormComplete(layoutResponse.is_payment_complete);
        setDeliveryFormComplete(layoutResponse.is_delivery_complete);
        setShippingFormComplete(layoutResponse.is_shipping_complete);

        setShippingFormErrored(layoutResponse.local_state === CheckoutOrderState.ADDRESS_ERROR);
        setDeliveryFormErrored(layoutResponse.local_state === CheckoutOrderState.OPTIONS_ERRORS);
        setPaymentFormErrored(layoutResponse.local_state === CheckoutOrderState.PAYMENT_ERRORS);

        setCheckoutError(layoutResponse.error);
        if (layoutResponse.error) {
            let failedForm = "";
            if (!layoutResponse.is_shipping_complete) {
                failedForm = CheckoutContextRequestErrorType.SHIPPING_FORM;
            } else if (!layoutResponse.is_delivery_complete) {
                failedForm = CheckoutContextRequestErrorType.DELIVERY_METHOD;
            } else if (!layoutResponse.is_payment_complete) {
                failedForm = CheckoutContextRequestErrorType.PAYMENT_METHOD;
            } else {
                failedForm = CheckoutContextRequestErrorType.BUY_NOW;
            }
            onFrontendError(
                layoutResponse.error.code as CheckoutRequestErrorCode,
                failedForm as CheckoutContextRequestErrorType,
                layoutResponse.error.message
            );

            // Notify the shopping-cart API  that the user has seen the error modal
            if (layoutResponse.error.code === CheckoutRequestErrorCode.PAYMENT_REFUSED) {
                notifyUserHasSeenError();
            }
        }
    }

    // TODO add response type
    function setPurchaseOutcome(response: any): void {
        setOrderSuccessful(response.data.is_successful);
        setOrderPending(response.data.is_pending);
        setCheckoutOrderState(response.data.local_state);

        if (response.data.is_pending) {
            goToView(CheckoutView.PURCHASE_PENDING);
            analytics.event(CheckoutContextRequestErrorType.PURCHASE_OUTCOME, "pending");
        } else if (response.data.is_successful) {
            goToView(CheckoutView.PURCHASE_SUCCESSFUL);
            analytics.event(CheckoutContextRequestErrorType.PURCHASE_OUTCOME, "succeeded");
        } else {
            goToView(CheckoutView.PURCHASE_FAILED);
            analytics.event(CheckoutContextRequestErrorType.PURCHASE_OUTCOME, "failed");

            // Notify the shopping-cart API that the user has seen the purchase failed page
            notifyUserHasSeenError();
        }
    }

    async function pollWaitForState(
        endpoint: string,
        onSuccess: (response: any) => void,
        onError: (error: any) => void,
        maxAttempts: number = _maxAttempts,
        requestData: any = {
            method: "GET",
        }
    ): Promise<any> {
        return callPollEndpoint(endpoint, requestData, onSuccess, onError, maxAttempts);
    }

    async function onSubmitShippingForm(): Promise<Response> {
        async function onSuccess(): Promise<void> {
            const shippingUrl = buildLanguageAwareUrlPath(CHECKOUT_SHIPPING_ENDPOINT);
            await pollWaitForState(shippingUrl, rehydrateUi, onError);

            setShippingLoadingSpinner(false);
            if (!checkoutError) setShippingFormErrored(false);
        }

        function onError(error: any): void {
            setShippingFormErrored(true);
            onFrontendError(
                CheckoutRequestErrorCode.GENERIC_ERROR,
                CheckoutContextRequestErrorType.SHIPPING_FORM,
                error?.message
            );
        }

        setShippingLoadingSpinner(true);

        const serializedBillingInfo =
            convertBillingShippingInformationToSerializer(billingInformation);
        const serializedShippingInfo =
            convertBillingShippingInformationToSerializer(shippingInformation);

        const requestData = {
            method: "POST",
            body: {
                billing_address: JSON.stringify(serializedBillingInfo),
                email: customerEmail.value,
                shipping_address: JSON.stringify(serializedShippingInfo),
            },
        };

        analytics.event(CheckoutContextRequestErrorType.SHIPPING_FORM, "clicked");

        const shippingUrl = buildLanguageAwareUrlPath(CHECKOUT_SHIPPING_ENDPOINT);
        return callEndpoint(shippingUrl, requestData, onSuccess, onError);
    }

    async function onClickDeliveryFormCTA(): Promise<Response | undefined> {
        async function onSuccess(): Promise<void> {
            const deliveryUrl = buildLanguageAwareUrlPath(CHECKOUT_DELIVERY_ENDPOINT);
            await pollWaitForState(deliveryUrl, rehydrateUi, onError);

            setDeliveryLoadingSpinner(false);
            if (!checkoutError) setDeliveryFormErrored(false);
        }

        function onError(error: any): void {
            setDeliveryFormErrored(true);
            onFrontendError(
                CheckoutRequestErrorCode.GENERIC_ERROR,
                CheckoutContextRequestErrorType.DELIVERY_METHOD,
                error?.message
            );
        }

        setDeliveryLoadingSpinner(true);

        const requestData = {
            method: "POST",
            body: {
                shipping_method_id: selectedShipping?.shipping_method_id,
            },
        };

        if (selectedShipping?.shipping_method_id === savedShipping?.shipping_method_id) {
            setDeliveryLoadingSpinner(false);
            setDeliveryFormComplete(true);
            setDeliveryFormErrored(false);
            goToLatestStep();

            return;
        }

        analytics.event(
            CheckoutContextRequestErrorType.DELIVERY_METHOD,
            "changed",
            selectedShipping?.shipping_method_title
        );

        const deliveryUrl = buildLanguageAwareUrlPath(CHECKOUT_DELIVERY_ENDPOINT);
        return callEndpoint(deliveryUrl, requestData, onSuccess, onError);
    }

    async function onPaymentFormSubmit(formData: any): Promise<Response | undefined> {
        const { cardName: name, useSavedData } = formData;

        if (useSavedData) {
            return;
        }

        if (!elements || !stripe) {
            // Stripe not yet loaded
            onFrontendError(
                CheckoutRequestErrorCode.GENERIC_ERROR,
                CheckoutContextRequestErrorType.PAYMENT_METHOD,
                "Stripe not yet loaded"
            );
            return;
        }

        const cardNumberElement = elements.getElement(CardNumberElement);
        if (!cardNumberElement) {
            // Could not extract card details from stripe element
            onFrontendError(
                CheckoutRequestErrorCode.GENERIC_ERROR,
                CheckoutContextRequestErrorType.PAYMENT_METHOD,
                "Stripe Card details not available"
            );
            return;
        }

        async function onSuccess(): Promise<void> {
            const paymentUrl = buildLanguageAwareUrlPath(CHECKOUT_PAYMENT_ENDPOINT);
            await pollWaitForState(paymentUrl, rehydrateUi, onError);

            setPaymentLoadingSpinner(false);
            if (!checkoutError) setPaymentFormErrored(false);
        }

        function onError(error): void {
            setPaymentFormErrored(true);
            onFrontendError(
                CheckoutRequestErrorCode.GENERIC_ERROR,
                CheckoutContextRequestErrorType.PAYMENT_METHOD,
                error?.message
            );
        }

        setPaymentLoadingSpinner(true);
        setPaymentFormName(name);

        const email = customerEmail.value;
        let stripePaymentReturn: Partial<StripePaymentReturn> = {};

        try {
            if (paymentProvider === PaymentProvider.STRIPE_TOKEN) {
                stripePaymentReturn = await createStripeToken(
                    stripe,
                    cardNumberElement,
                    billingInformation.address
                );
            } else if (paymentProvider === PaymentProvider.STRIPE_PAYMENT_INTENT) {
                stripePaymentReturn = await createStripePaymentMethod(
                    stripe,
                    cardNumberElement,
                    billingInformation,
                    email,
                    name
                );
            } else {
                onFrontendError(
                    CheckoutRequestErrorCode.GENERIC_ERROR,
                    CheckoutContextRequestErrorType.PAYMENT_METHOD,
                    "Stripe Unhandled creation error"
                );
                return;
            }
        } catch (e) {
            onFrontendError(
                CheckoutRequestErrorCode.GENERIC_ERROR,
                CheckoutContextRequestErrorType.PAYMENT_METHOD,
                "Stripe creation error"
            );
            return;
        }

        if (stripePaymentReturn.error) {
            // TODO i18n
            if (stripePaymentReturn.error?.["decline_code"] === "card_not_supported") {
                onFrontendError(
                    CheckoutRequestErrorCode.UNSUPPORTED_CARD,
                    CheckoutContextRequestErrorType.PAYMENT_METHOD
                );
            } else {
                onFrontendError(
                    CheckoutRequestErrorCode.GENERIC_ERROR,
                    CheckoutContextRequestErrorType.PAYMENT_METHOD,
                    `Stripe card declined: ${stripePaymentReturn.error?.["decline_code"]}`
                );
            }

            return;
        }

        if (!stripePaymentReturn.card) {
            // Error not caught by stripe somehow
            onFrontendError(
                CheckoutRequestErrorCode.GENERIC_ERROR,
                CheckoutContextRequestErrorType.PAYMENT_METHOD,
                "Stripe card missing error"
            );
            return;
        }

        const { brand, last4 } = stripePaymentReturn.card;

        if (!brand || !last4) {
            // Error not caught by stripe somehow
            onFrontendError(
                CheckoutRequestErrorCode.GENERIC_ERROR,
                CheckoutContextRequestErrorType.PAYMENT_METHOD,
                "Stripe brand or card info error"
            );
            return;
        }

        const normalizedBrand = normalizeCardBrand(brand);

        // Stripe returned either an "Unknown" brand, or we were unable to map the brand given by
        // Stripe to our list of known card brands.
        if (normalizedBrand === "" || !normalizedBrand) {
            onFrontendError(
                CheckoutRequestErrorCode.GENERIC_ERROR,
                CheckoutContextRequestErrorType.PAYMENT_METHOD,
                `Stripe brand not recognised: ${brand}`
            );
            return;
        }

        onPaymentInformationChange({
            paymentFormLast4: last4,
            paymentFormCardType: normalizedBrand,
        });

        const providerKey = CheckoutPaymentProviderIdKeys[paymentProvider as string];

        const requestData = {
            method: "POST",
            body: {
                payment_information: JSON.stringify({
                    card_provider: normalizedBrand,
                    card_last_4: last4,
                    [providerKey]: stripePaymentReturn.id,
                    payment_type: PaymentTypes.CARD,
                }),
            },
        };

        analytics.event(CheckoutContextRequestErrorType.PAYMENT_METHOD, "added", normalizedBrand);

        const paymentUrl = buildLanguageAwareUrlPath(CHECKOUT_PAYMENT_ENDPOINT);
        return callEndpoint(paymentUrl, requestData, onSuccess, onError);
    }

    async function onClickBuyNowCTA(): Promise<Response> {
        async function onSuccess(): Promise<void> {
            await confirmPayment();
        }

        function onError(error: any): void {
            onFrontendError(
                CheckoutRequestErrorCode.GENERIC_ERROR,
                CheckoutContextRequestErrorType.BUY_NOW,
                error?.message
            );
        }

        setFullPageLoadingSpinner(true);

        const requestData = {
            method: "POST",
            body: {
                is_marketing_preference_enabled: isMarketingPreferenceChecked,
            },
        };

        analytics.event(
            CheckoutContextRequestErrorType.MARKETING_PREFERENCE,
            "clicked",
            isMarketingPreferenceChecked.toString()
        );
        analytics.event(CheckoutContextRequestErrorType.BUY_NOW, "clicked", "card");

        const purchaseUrl = buildLanguageAwareUrlPath(CHECKOUT_PURCHASE_ENDPOINT);
        return callEndpoint(purchaseUrl, requestData, onSuccess, onError);
    }

    async function confirmPayment(): Promise<Response> {
        async function onSuccess(response): Promise<void> {
            const checkoutLayout: CheckoutLayoutSerializer =
                response.data.checkout?.checkout_layout;
            const purchaseUrl = buildLanguageAwareUrlPath(CHECKOUT_PURCHASE_ENDPOINT);

            if (
                checkoutLayout?.local_state === CheckoutOrderState.THREE_DS_CHALLENGED &&
                checkoutLayout?.three_ds_challenge
            ) {
                setThreeDsChallenged(true);
                setThreeDsUrl(checkoutLayout.three_ds_challenge.url);
                await pollWaitForState(purchaseUrl, onThreeDsSuccess, onThreeDsError, 100, {
                    method: "GET",
                    query: { is_three_ds_challenge_attempt: true },
                });
            } else {
                if (checkoutLayout?.error) {
                    rehydrateUi(response);
                } else {
                    setPurchaseOutcome(response);
                }
                setFullPageLoadingSpinner(false);
            }
        }

        async function onThreeDsSuccess(): Promise<void> {
            resetThreeDsChallenged();
            analytics.event(CheckoutContextRequestErrorType.THREE_DS_CHALLENGE, "succeeded");
            const purchaseUrl = buildLanguageAwareUrlPath(CHECKOUT_PURCHASE_ENDPOINT);
            await pollWaitForState(purchaseUrl, onSuccess, onError);
        }

        function onError(error: any): void {
            onFrontendError(
                CheckoutRequestErrorCode.GENERIC_ERROR,
                CheckoutContextRequestErrorType.BUY_NOW,
                error?.message
            );
        }

        function onThreeDsError(): void {
            resetThreeDsChallenged();
            setFullPageLoadingSpinner(false);
            analytics.event(CheckoutContextRequestErrorType.THREE_DS_CHALLENGE, "failed");
            onFrontendError(
                CheckoutRequestErrorCode.GENERIC_ERROR,
                CheckoutContextRequestErrorType.BUY_NOW,
                "3DS challenge error"
            );

            // Stripe token can only be used once and will need to be regenerated after a 3DS challenge failure
            // We have a bug where the PaymentMethod does not have a customer attached so can only be used once
            // Forcing the user to resubmit their payment information will trigger this
            if (isMobileViewport) {
                // On mobile view we lose the payment form state when we go back to the payment form
                // so we need to clear the payment form to force the user to re-enter their details
                onPaymentInformationChange({
                    paymentFormLast4: "",
                    paymentFormCardType: null,
                });
            }
            setPaymentFormComplete(false);
            goToView(CheckoutView.PAYMENT);
        }

        const requestData = {
            method: "GET",
            query: { is_marketing_preference_enabled: isMarketingPreferenceChecked },
        };

        const purchaseUrl = buildLanguageAwareUrlPath(CHECKOUT_PURCHASE_ENDPOINT);
        return callPollEndpoint(purchaseUrl, requestData, onSuccess, onError);
    }

    async function notifyUserHasSeenError(): Promise<Response> {
        const requestData = { method: "POST" };
        const errorUrl = buildLanguageAwareUrlPath(CHECKOUT_USER_HAS_SEEN_ERROR_ENDPOINT);
        return callEndpoint(errorUrl, requestData, noop, noop);
    }

    const deliveryForm = {
        isDeliveryFormComplete,
        isDeliveryFormDisabled,
        hasDeliveryFormErrored,
        onClickDeliveryFormCTA,
        onDeliverySelect,
        selectedShipping,
        shippingMethods,
        showDeliveryLoadingSpinner,
    };

    const marketingPreferences = {
        isMarketingPreferenceChecked,
        setMarketingPreferenceCheck,
        showMarketingPreferences,
        optInMarketingText,
    };

    const paymentForm = {
        isPaymentFormDisabled,
        isPaymentFormComplete,
        hasPaymentFormErrored,
        onPaymentFormSubmit,
        onPaymentInformationChange,
        paymentFormName,
        showPaymentLoadingSpinner,
        ...paymentFormData,
    };

    const shippingForm = {
        billingInformation,
        customerEmail,
        isShippingFormComplete,
        isShippingFormDisabled,
        hasShippingFormErrored,
        onBillingInformationChange,
        onCustomerEmailChange,
        onShippingInformationChange,
        onSubmitShippingForm,
        shippingInformation,
        showShippingLoadingSpinner,
        sendShippingFormChangedAnalytics,
        validateShippingBillingForm,
    };

    const purchaseOutcome = {
        orderNumber,
        isOrderSuccessful,
        isOrderPending,
        selectedShipping,
    };

    const threeDsChallengedModal = {
        isThreeDsChallenged,
        threeDsUrl,
        resetThreeDsChallenged,
    };

    const errorModal = {
        isErrorModalOpen,
        setErrorModal,
        errorTitle,
        errorDescription,
        errorCTAText,
    };

    const views = {
        activeView,
        pageTitle,
        goToView,
        onClickBackButton,
        exitCheckoutPage,
        stayInCheckout,
        showCheckoutExitPrompt,
        triggerCheckoutExit,
    };

    return (
        <CheckoutContext.Provider
            value={{
                country,
                costSummary,
                customerEmail,
                deliveryForm,
                marketingPreferences,
                paymentForm,
                shippingForm,
                purchaseOutcome,
                threeDsChallengedModal,
                products,
                onToggleAccordion,
                onClickBuyNowCTA,
                isBuyNowEnabled,
                showFullPageLoadingSpinner,
                errorModal,
                views,
            }}
        >
            {children}
        </CheckoutContext.Provider>
    );
};

export { CheckoutView, VIEW_TITLES };
export default CheckoutContextProvider;
