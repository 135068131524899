import "web/react/emo/theme.css.ts.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"web/react/emo/theme.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA6WUTW/bMAyG7/sVRHzZgHSwPizL3Em2JayH7bDuPjip06b5cJqkSdth/32gMxQzPZ0G+fRQpqiXfIX7rjvCz3cAV1c/xP52JcU5RUjSNP00gAIhsdpY4YdcIiRBhdQXQ64QkjJzOrdDrhGSIs1zlQ15hpAYlynB9hvK40sV5JDnCImvvA3lkFuEpCorGVj+guovrHBhyBuEJAuZy1n9M4REV9oaMeRzhKRW0kumwy3p4L3zbshb4qVVluVZEHc60+xed6RPJmrB7nWPkMhamcDqX1Kzgig5f+ibaF3KdFiRbiY4nw/5mvQvvcpZ3zd9HhdStn/bc21SNeRdRJ9dRJ/HiD77iD6HiD7HPn9h+dw+EQ8h82bIT/3+ShjGzz13tWL3eu7nKnc8/wtCIpwyJeOvpLP1ZWC6CXJXbqypucHIYSKTpa1ZgCyWOmE0K1WQx2QurWSzK3SkKEEuqx0tFjB9G4ILPEA+C1nQ3H+CjFb7uq55VUXsjObSisBHVcxih88vj4sKrBmC3OZzWizQxg4nv1WeFguQ4SpNiwXIcUVJiwXIcnlKiwXIc9rTYoFVrFHry1M7fm03CPu7WfM+ncKf76P+wPZsYz93sQJ3sT8eI6+7IBvm0pQFs6c4RHwujpGHR5ATq1wayYU+RawlzrExfo5N0kss8BppjUwjkkgREVHKyMBIhcD3agS9e2YwQ7AjaBCEHNEcQZgRtQhynLZAUOmINghqnHeGoMd75wh6XNktghmf1iLYcYYFQjGu947uNt58j3DztDwcWrjeHtcsuHwLfum2HQs+IFyfmj3cdIsjC60GSacw+dyuT+1xOW/ga/vUTqbwBqbg9stmPYVDsz1cHdr9csGSrf86ZwqT78tNe4Cv7Rm+dZtmO5lCT6bwr383gwv8ZyFb6hcfrg5BZxzuELLRzkcEM4J7BKEuM/frN5j8aoMHCgAA\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "web/react/emo/typography.css.ts.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"web/react/emo/typography.css.ts.vanilla.css\",\"source\":\"LnZqbGliczAgewogIG1hcmdpbjogMDsKICBwYWRkaW5nOiAwOwp9Ci52amxpYnMxIHsKICB0ZXh0LWRlY29yYXRpb246IGxpbmUtdGhyb3VnaDsKfQoudmpsaWJzMiBhIHsKICBjb2xvcjogdmFyKC0tXzFyZGsyMXcxbik7CiAgdGV4dC1kZWNvcmF0aW9uOiB1bmRlcmxpbmU7Cn0KLnZqbGliczMgewogIHdoaXRlLXNwYWNlOiBub3dyYXA7CiAgb3ZlcmZsb3c6IGhpZGRlbjsKICB0ZXh0LW92ZXJmbG93OiBlbGxpcHNpczsKfQoudmpsaWJzNSB7CiAgZGlzcGxheTogLXdlYmtpdC1ib3g7CiAgLXdlYmtpdC1ib3gtb3JpZW50OiB2ZXJ0aWNhbDsKICAtd2Via2l0LWxpbmUtY2xhbXA6IHZhcigtLXZqbGliczQpOwogIG92ZXJmbG93OiBoaWRkZW47Cn0KLnZqbGliczUgcCB7CiAgZGlzcGxheTogaW5saW5lOwogIHRleHQtb3ZlcmZsb3c6IGVsbGlwc2lzOwp9Ci52amxpYnM2IHsKICB0ZXh0LXRyYW5zZm9ybTogdXBwZXJjYXNlOwp9Ci52amxpYnM3IHsKICB0ZXh0LWRlY29yYXRpb246IHVuZGVybGluZTsKfQoudmpsaWJzOCB7CiAgd29yZC1icmVhazogYnJlYWstd29yZDsKfQ==\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var body1 = {fontWeight:'var(--_1rdk21w2n)',fontSize:'18px',fontFamily:'var(--_1rdk21w2k)',lineHeight:1.3333333333};
export var body2 = {fontWeight:'var(--_1rdk21w2o)',fontSize:'16px',fontFamily:'var(--_1rdk21w2k)',lineHeight:1.5};
export var body2LEGACY = {fontWeight:'var(--_1rdk21w2o)',fontSize:'16px',fontFamily:'var(--_1rdk21w2k)',lineHeight:1.5};
export var body3 = {fontWeight:'var(--_1rdk21w2o)',fontSize:'16px',fontFamily:'var(--_1rdk21w2k)',lineHeight:1.5};
export var body3Small = {fontWeight:'var(--_1rdk21w2o)',fontSize:'14px',fontFamily:'var(--_1rdk21w2k)',lineHeight:1.71428571};
export var callout = {fontWeight:'var(--_1rdk21w2n)',fontSize:'16px',fontFamily:'var(--_1rdk21w2m)',lineHeight:1.5};
export var calloutLEGACY = {fontWeight:'var(--_1rdk21w2p)',fontSize:'16px',fontFamily:'var(--_1rdk21w2k)',lineHeight:1.25};
export var calloutSmall = {fontWeight:'var(--_1rdk21w2p)',fontSize:'14px',fontFamily:'var(--_1rdk21w2m)',lineHeight:1.42857143};
export var caption1Small = {fontWeight:'var(--_1rdk21w2p)',fontSize:'14px',fontFamily:'var(--_1rdk21w2k)',lineHeight:1.2857142857,letterSpacing:'0.25px'};
export var caption2Small = {fontWeight:'var(--_1rdk21w2q)',fontSize:'12px',fontFamily:'var(--_1rdk21w2k)',lineHeight:1.1666666667,letterSpacing:'1px',textTransform:'uppercase'};
export var caption3 = {fontWeight:'var(--_1rdk21w2n)',fontSize:'12px',fontFamily:'var(--_1rdk21w2m)',lineHeight:1.33,letterSpacing:'0px'};
export var caption3Small = {fontWeight:'var(--_1rdk21w2n)',fontSize:'10px',fontFamily:'var(--_1rdk21w2m)',lineHeight:1.3,letterSpacing:'1px'};
export var footnote = {fontWeight:'var(--_1rdk21w2o)',fontSize:'14px',fontFamily:'var(--_1rdk21w2k)',lineHeight:1.2857142857,letterSpacing:'0.25px'};
export var footnoteSmall = {fontWeight:'var(--_1rdk21w2o)',fontSize:'12px',fontFamily:'var(--_1rdk21w2k)',lineHeight:1.3333333333,letterSpacing:'0.25px'};
export var smallLink = {fontWeight:'var(--_1rdk21w2p)',fontSize:'14px',fontFamily:'var(--_1rdk21w2k)',lineHeight:1.25,letterSpacing:'0'};
export var bodyStyles = {'body-1':body1,'body-2':body2LEGACY,'body-2-v2':body2,'body-3-small':body3Small,'body-3':body3,callout:calloutLEGACY,'callout-v2':callout,'callout-small':smallCalloutLEGACY,'callout-small-v2':calloutSmall,footnote:footnote,'footnote-small':footnoteSmall,'caption-1':caption1Small,'caption-2':caption2Small,'caption-3-small':caption3Small,'caption-3':caption3,'small-link':smallLink};
export var headline = {fontWeight:'var(--_1rdk21w2n)',fontSize:'28px',fontFamily:'var(--_1rdk21w2l)',lineHeight:1.14285714286};
export var headlineLEGACY = {fontWeight:'var(--_1rdk21w2n)',fontSize:'18px',fontFamily:'var(--_1rdk21w2l)',lineHeight:1.3333333333,letterSpacing:'0.25px'};
export var headlineSmall = {fontWeight:'var(--_1rdk21w2n)',fontSize:'24px',fontFamily:'var(--_1rdk21w2l)',lineHeight:1.5};
export var largeTitle = {fontWeight:'var(--_1rdk21w2n)',fontSize:'42px',fontFamily:'var(--_1rdk21w2l)',lineHeight:1.14285714286};
export var largeTitle2 = {fontWeight:'var(--_1rdk21w2n)',fontSize:'56px',fontFamily:'var(--_1rdk21w2m)',lineHeight:1.1428571429};
export var largeTitle2LEGACY = {fontWeight:'var(--_1rdk21w2n)',fontSize:'32px',fontFamily:'var(--_1rdk21w2l)',lineHeight:1.2857142857};
export var largeTitleLegacy = {fontWeight:'var(--_1rdk21w2n)',fontSize:'36px',fontFamily:'var(--_1rdk21w2l)',lineHeight:1.2222222222};
export var subheadSmall = {fontWeight:'var(--_1rdk21w2p)',fontSize:'18px',fontFamily:'var(--_1rdk21w2k)',lineHeight:1.3333333333};
export var title1LEGACY = {fontWeight:'var(--_1rdk21w2n)',fontSize:'28px',fontFamily:'var(--_1rdk21w2l)',lineHeight:'1.2857142857'};
export var title2 = {fontWeight:'var(--_1rdk21w2n)',fontSize:'18px',fontFamily:'var(--_1rdk21w2m)',lineHeight:1.127777778,letterSpacing:'-0.25px'};
export var title2LEGACY = {fontWeight:'var(--_1rdk21w2n)',fontSize:'24px',fontFamily:'var(--_1rdk21w2l)',lineHeight:1.1666666667,letterSpacing:'0.2px'};
export var title3LEGACY = {fontWeight:'var(--_1rdk21w2n)',fontSize:'20px',fontFamily:'var(--_1rdk21w2l)',lineHeight:1.2,letterSpacing:'0.25px'};
export var headingStyles = {'xxl-title':xxlTitle,'xl-large-title':xlLargeTitle,'large-title':largeTitleLegacy,'large-title-v2':largeTitle,'large-title-2':largeTitle2LEGACY,'large-title-2-v2':largeTitle2,'title-1':title1LEGACY,'title-2':title2LEGACY,'title-3':title3LEGACY,'title-1-v2':title1,'title-2-v2':title2,'title-3-v2':title3,headline:headlineLEGACY,'headline-v2':headline,'headline-small-v2':headlineSmall,subhead:subheadSmall};
export var lineClamp = 'vjlibs5';
export var lineClampVar = 'var(--vjlibs4)';
export var linkReset = 'vjlibs2';
export var noSpacing = 'vjlibs0';
export var smallCalloutLEGACY = {fontWeight:'var(--_1rdk21w2p)',fontSize:'14px',fontFamily:'var(--_1rdk21w2k)',lineHeight:1.25};
export var strikeThrough = 'vjlibs1';
export var title1 = {fontWeight:'var(--_1rdk21w2n)',fontSize:'28px',fontFamily:'var(--_1rdk21w2m)',lineHeight:1.2857142857,letterSpacing:'-0.25px'};
export var title3 = {fontWeight:'var(--_1rdk21w2n)',fontSize:'16px',fontFamily:'var(--_1rdk21w2m)',lineHeight:1.3125,letterSpacing:'-0.25px'};
export var truncate = 'vjlibs3';
export var underline = 'vjlibs7';
export var upperCase = 'vjlibs6';
export var wordBreak = 'vjlibs8';
export var xlLargeTitle = {fontWeight:'var(--_1rdk21w2n)',fontSize:'56px',fontFamily:'var(--_1rdk21w2l)',lineHeight:1.1428571429};
export var xxlTitle = {fontWeight:'var(--_1rdk21w2p)',fontSize:'72px',fontFamily:'var(--_1rdk21w2l)',lineHeight:1.1666666667};