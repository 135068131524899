import clsx from "clsx";
import React from "react";
import SVGIcon from "web/react/components/svg-icon/svg-icon";
import { gettext } from "web/script/modules/django-i18n";
import { useProductCardContext } from "./product-card";
// eslint-disable-next-line css-modules/no-unused-class
import styles from "./product-card-details.module.css";

export function ProductCardDetailsCuratedAd(): React.ReactElement {
    const product = useProductCardContext();

    return (
        <div className={styles.details}>
            <div className={styles.designer}>{product.designer_name}</div>
            <div className={styles.shortDescription}>{product.short_description}</div>

            {product.context_type !== "collection_products" && (
                <div className={styles.priceSection}>
                    {product.full_price_with_currency_symbol ? (
                        !product.in_stock ? (
                            <React.Fragment>
                                <del className={styles.price}>
                                    {product.full_price_with_currency_symbol}
                                </del>

                                <span className={styles.soldOutMessage}>
                                    {gettext("product_card.sold_out")}
                                </span>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                {product.free_shipping && (
                                    <SVGIcon className={styles.icon} name="truck" />
                                )}
                                {product.sale_discount ? (
                                    <React.Fragment>
                                        <del className={styles.price}>
                                            {product.full_price_with_currency_symbol}
                                        </del>
                                        <span className={clsx(styles.price, styles.salePrice)}>
                                            {product.sale_price_with_currency_symbol}
                                        </span>
                                    </React.Fragment>
                                ) : (
                                    <span className={styles.price}>
                                        {product.full_price_with_currency_symbol}
                                    </span>
                                )}

                                {product.discount_info && (
                                    <span className={styles.discountInfo}>
                                        {product.discount_info}
                                    </span>
                                )}
                            </React.Fragment>
                        )
                    ) : (
                        <span className={styles.soldOutMessage}>
                            {gettext("product_card.not_available_in_your_country")}
                        </span>
                    )}
                </div>
            )}

            <div className={styles.retailer}>
                {product.show_retailer_as_affiliate && <SVGIcon name="hyperlink" />}
                <span className={styles.displayedRetailer}>
                    {
                        // Sponsored by { retailer_name }
                        gettext("in_stock_product.sponsored_by_retailer", {
                            retailer_name: product.retailer_name,
                        })
                    }
                </span>
            </div>
        </div>
    );
}
