import clsx from "clsx";
import React, { useState } from "react";
import { Button } from "web/react/emo/button";
import { Heading } from "web/react/emo/heading";
import { Text } from "web/react/emo/text";
import { gettext } from "web/script/modules/django-i18n";
import { RequesterError } from "web/script/modules/errors";
import requester from "web/script/modules/requester";
import { ChangePasswordPageSerializer } from "web/types/serializers";
import * as styles from "./change-password.css";

export default function ChangePassword({
    min_password_length: minPasswordLength,
    max_password_length: maxPasswordLength,
    password_reset_link: passwordResetLink,
    user_does_not_have_usable_password: userDoesNotHaveUsablePassword,
}: ChangePasswordPageSerializer): React.ReactElement {
    const [loading, setLoading] = useState(false);
    const [hasBeenSaved, setHasBeenSaved] = useState(false);
    const [generalError, setGeneralError] = useState(false);
    const [errors, setErrors] = useState<Record<string, string[]>>({
        old_password: [],
        new_password1: [],
        new_password2: [],
    });
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword1, setNewPassword1] = useState("");
    const [newPassword2, setNewPassword2] = useState("");

    async function handleSubmit(e: React.FormEvent): Promise<void> {
        e.preventDefault();

        setErrors({
            old_password: [],
            new_password1: [],
            new_password2: [],
        });

        try {
            setLoading(true);
            await requester("/account/change-password/", {
                body: {
                    old_password: oldPassword,
                    new_password1: newPassword1,
                    new_password2: newPassword2,
                } as any,
                method: "POST",
            });
            setHasBeenSaved(true);

            // Reload the page because the session is invalidated
            // and we require the user to login again
            window.location.reload();
        } catch (e) {
            if (e instanceof RequesterError) {
                const responseData = await e.response.json();
                setErrors((current) => ({
                    ...current,
                    ...responseData,
                }));
            } else {
                setGeneralError(true);
            }
        } finally {
            setLoading(false);
        }
    }

    return (
        <div className={styles.content}>
            <Heading as="h2" textStyle="large-title" className={styles.title}>
                {gettext("settings.change_password.title")}
            </Heading>

            {hasBeenSaved && (
                <div className={clsx(styles.box, styles.success)}>
                    <Text as="p" textStyle="body-3">
                        {gettext("settings.account.save_success_alert_txt")}
                    </Text>
                </div>
            )}

            {userDoesNotHaveUsablePassword && (
                <div className={clsx(styles.box, styles.warning)}>
                    <Text as="p" textStyle="body-3">
                        <span
                            dangerouslySetInnerHTML={{
                                __html: gettext("settings.account.set_password_prompt", {
                                    password_reset_link_props: `href="${passwordResetLink}"`,
                                }),
                            }}
                        />
                    </Text>
                </div>
            )}

            <form
                className="form-bootstrap"
                method="post"
                id="settings_form"
                encType="multipart/form-data"
                onSubmit={handleSubmit}
            >
                {/*
                    We need this fake input to prevent Chrome and Safari to autocomplete name and password.
                    The fields can't be hidden via display none so we have to use a hack to hide them.
                    Background: https://groups.google.com/a/chromium.org/forum/#!topic/security-dev/wYGThW5WRrE
                */}
                <div className={styles.fakeHiddenInput}>
                    <input type="password" name="fakepasswordforchrome" />
                </div>

                <div className="row">
                    <fieldset className={styles.fieldSet}>
                        <label htmlFor="id_old_password" className={styles.label}>
                            <Text as="span" textStyle="body-3">
                                {gettext("settings.account.form.current_password_label")}
                            </Text>
                        </label>

                        <input
                            type="password"
                            name="old_password"
                            title={gettext("input.field.required")}
                            required
                            id="id_old_password"
                            value={oldPassword}
                            onChange={(e) => setOldPassword(e.target.value)}
                            className={styles.input}
                        />

                        <ul className={styles.errorsList}>
                            {errors.old_password.map((err) => (
                                <li key={err}>
                                    <Text as="span" textStyle="footnote-small">
                                        {err}
                                    </Text>
                                </li>
                            ))}
                        </ul>
                    </fieldset>

                    <fieldset className={styles.fieldSet}>
                        <label htmlFor="id_new_password1" className={styles.label}>
                            <Text as="span" textStyle="body-3">
                                {gettext("settings.account.form.new_password_label")}
                            </Text>
                        </label>

                        <input
                            type="password"
                            name="new_password1"
                            title={gettext("account.settings.password_min_length", {
                                min_chars_count: minPasswordLength,
                            })}
                            maxLength={maxPasswordLength}
                            minLength={minPasswordLength}
                            id="id_new_password1"
                            value={newPassword1}
                            onChange={(e) => setNewPassword1(e.target.value)}
                            className={styles.input}
                        />

                        <ul className={styles.errorsList}>
                            {errors.new_password1.map((err) => (
                                <li key={err}>
                                    <Text as="span" textStyle="footnote-small">
                                        {err}
                                    </Text>
                                </li>
                            ))}
                        </ul>
                    </fieldset>

                    <fieldset className={styles.fieldSet}>
                        <label htmlFor="id_new_password2" className={styles.label}>
                            <Text as="span" textStyle="body-3">
                                {gettext("settings.account.form.confirm_new_password_label")}
                            </Text>
                        </label>

                        <input
                            type="password"
                            name="new_password2"
                            title={gettext("account.settings.password_min_length", {
                                min_chars_count: minPasswordLength,
                            })}
                            maxLength={maxPasswordLength}
                            minLength={minPasswordLength}
                            id="id_new_password2"
                            value={newPassword2}
                            onChange={(e) => setNewPassword2(e.target.value)}
                            className={styles.input}
                        />

                        <ul className={styles.errorsList}>
                            {errors.new_password2.map((err) => (
                                <li key={err}>
                                    <Text as="span" textStyle="footnote-small">
                                        {err}
                                    </Text>
                                </li>
                            ))}
                        </ul>
                    </fieldset>

                    <Button
                        title={gettext("account.save_new_password.cta")}
                        type="submit"
                        disabled={
                            userDoesNotHaveUsablePassword ||
                            !oldPassword ||
                            !newPassword1 ||
                            !newPassword2 ||
                            loading
                        }
                    />

                    {generalError && (
                        <Text as="p" textStyle="body-3" className={styles.error}>
                            {gettext("error.400.title")}
                        </Text>
                    )}
                </div>
            </form>
        </div>
    );
}
