import { clsx } from "clsx";
import React, { useState } from "react";
import { COOKIE_COUNTRIES } from "web/react/constants";
import { useDomViewport } from "web/react/hooks/use-dom-viewport/use-dom-viewport";
import { gettext } from "web/script/modules/django-i18n";
import globals from "web/script/modules/globals";
import { languageIs, languageIsEnglish } from "web/script/modules/language";
import OneTrustCookie from "web/script/modules/one-trust-cookie";
import { shouldShowOmnibusPages } from "web/script/modules/utils";
import { getUrl } from "web/script/routing/getUrl";
import { useFooterContext } from "./footer";
import FooterBlock from "./footer-block";
import styles from "./footer-help-info.module.css";
import { FooterUrls } from "./footer-urls";

export const HELP = "help";

function FooterHelpInfo(): JSX.Element {
    const { helpCentreURLs, openBlockId, setOpenBlock, country } = useFooterContext();

    const { isDomLoaded } = useDomViewport();

    // Get the country from OneTrust in order to decide
    // if we should show the cookie settings in the footer
    const [geoLocatedCountry, setGeoLocatedCountry] = useState(
        OneTrustCookie.getGeoLocationCountry() || country
    );
    globals.window.addEventListener("OneTrustIsLoaded", function () {
        const geolocation = OneTrustCookie.getGeoLocationCountry();
        if (geolocation) {
            setGeoLocatedCountry(geolocation);
        }
    });

    return (
        <div data-testid="help-and-info">
            <FooterBlock
                title={gettext("footer.help_info.title_variant_b")}
                isOpen={openBlockId == HELP}
                onClick={(): void => setOpenBlock(HELP)}
            >
                <ul>
                    <li>
                        <a className={styles.link} href={helpCentreURLs["homepage"]}>
                            {gettext("footer.help_info.help_center_link")}
                            {/* Help Center */}
                        </a>
                    </li>
                    <li>
                        <a className={styles.link} href={getUrl("about")}>
                            {gettext("footer.links_about_us")}
                            {/* About us */}
                        </a>
                    </li>
                    <li>
                        <a className={styles.link} href={helpCentreURLs["shipping_policy"]}>
                            {gettext("footer.help_info.shipping_policy_link")}
                            {/* Shipping Policy */}
                        </a>
                    </li>
                    <li>
                        <a className={styles.link} href={helpCentreURLs["returns_policy"]}>
                            {gettext("footer.help_info.returns_policy_link")}
                            {/* Returns Policy */}
                        </a>
                    </li>
                    {languageIsEnglish() && (
                        <>
                            <li className="footer-link-item">
                                <a className={styles.link} href={helpCentreURLs["payments"]}>
                                    {gettext("footer.help_info.payments_link")}
                                </a>
                                {/* Payments */}
                            </li>
                            <li className="footer-link-item">
                                <a className={styles.link} href={helpCentreURLs["refund_policy"]}>
                                    {gettext("footer.help_info.refund_policy_link")}
                                </a>
                                {/* Refunds Policy */}
                            </li>
                            <li>
                                <a className={styles.link} href={FooterUrls["makingLyst"]}>
                                    {gettext("footer.help_info.developers_link")}
                                    {/* Developers */}
                                </a>
                            </li>
                        </>
                    )}
                    <li>
                        <a className={styles.link} href={getUrl("careers")}>
                            {gettext("footer.links_careers")}
                            {/* Careers */}
                        </a>
                    </li>
                    <li>
                        <a className={styles.link} href={getUrl("helpContactUs")}>
                            {gettext("footer.help_info.contact_link")}
                            {/* Contact */}
                        </a>
                    </li>
                    <li>
                        <a className={styles.link} href={getUrl("helpTermsAndConditions")}>
                            {gettext("footer.help_info.terms_and_conditions_link")}
                            {/* Terms & Conditions */}
                        </a>
                    </li>
                    <li>
                        <a className={styles.link} href={getUrl("helpPrivacyPolicy")}>
                            {gettext("footer.help_info.privacy_and_cookie_policy_link")}
                            {/* Privacy & Cookie Policy */}
                        </a>
                    </li>
                    <li>
                        <a className={styles.link} href={getUrl("helpIntellectualProperty")}>
                            {gettext("footer.help_info.intellectual_property_link")}
                            {/* Intellectual Property */}
                        </a>
                    </li>
                    {shouldShowOmnibusPages() && (
                        <li>
                            <a className={styles.link} href={getUrl("helpProductRanking")}>
                                {gettext("footer.help_info.product_ranking_link")}
                                {/* Product ranking information */}
                            </a>
                        </li>
                    )}
                    {shouldShowOmnibusPages() && (
                        <li>
                            <a className={styles.link} href={getUrl("helpPrivateSellers")}>
                                {gettext("footer.help_info.private_sellers_link")}
                                {/* Buying from individuals */}
                            </a>
                        </li>
                    )}
                    <li>
                        <a className={styles.link} href={getUrl("sitemapsAllCategories")}>
                            {gettext("footer.links_categories")}
                            {/* Categories */}
                        </a>
                    </li>
                    {/* German language footer gets an extra link to Impressum section of the privacy policy. */}
                    {languageIs("DE") && (
                        <li>
                            <a
                                className={styles.link}
                                href={`${getUrl("helpPrivacyPolicy")}#impressum`}
                            >
                                {"Impressum"}
                            </a>
                        </li>
                    )}
                    <li>
                        <a className={styles.link} href={getUrl("partnerContact")}>
                            {gettext("footer.help_info.become_a_partner_link")}
                            {/* Become a Partner */}
                        </a>
                    </li>
                    {languageIsEnglish() && (
                        <>
                            <li>
                                <a className={styles.link} href={FooterUrls["lystInsights"]}>
                                    {gettext("footer.links_lyst_insights")}
                                    {/* Lyst Insights */}
                                </a>
                            </li>
                            <li>
                                <a className={styles.link} href={FooterUrls["lystNews"]}>
                                    {gettext("footer.links_lyst_news")}
                                    {/* Lyst News */}
                                </a>
                            </li>
                        </>
                    )}
                    {isDomLoaded && COOKIE_COUNTRIES.has(geoLocatedCountry) && (
                        <li>
                            <a
                                id="ot-sdk-btn"
                                // styling applied directly in OneTrust platform
                                className={clsx(styles.link, "ot-sdk-show-settings")}
                            >
                                {geoLocatedCountry === "US"
                                    ? // Do not sell or share my personal information
                                      gettext(
                                          "privacy_policy.california_privacy_rights.do_not_sell_or_share.label"
                                      )
                                    : // Cookie Settings
                                      gettext("cookie_notification.cookie_settings.title")}
                            </a>
                        </li>
                    )}
                    <li>
                        <a className={styles.link} href={getUrl("helpModernSlaveryStatement")}>
                            {gettext("footer.help_info.modern_slavery_statement")}
                            {/* Modern slavery statement */}
                        </a>
                    </li>
                    <li>
                        <a className={styles.link} href={getUrl("helpS172Statement")}>
                            {gettext("footer.help_info.s172_statement")}
                            {/* s172 statement */}
                        </a>
                    </li>
                    <li>
                        <a className={styles.link} href={getUrl("helpResponsibleSourcing")}>
                            {gettext("footer.help_info.responsible_sourcing")}
                            {/* Responsible sourcing */}
                        </a>
                    </li>
                    <li>
                        <a className={styles.link} href={getUrl("helpCodeOfConduct")}>
                            {gettext("footer.help_info.code_of_conduct")}
                            {/* Code of conduct */}
                        </a>
                    </li>
                </ul>
            </FooterBlock>
        </div>
    );
}

export default FooterHelpInfo;
