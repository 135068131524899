// This component is an attempt to build a compound component so we can compose it however we like. It is future proof in order to easily extend and make different variations of the product card
// Usage by composition:
/*
    import ProductCard from "web/react/components/product-card/react";
    ...
    <ProductCard product={product_data}>
        {any ProductCard element or custom element that we want}

        <ProductCard.Image width={<number>} height={<number>}>
            {any ProductCard element or custom element that we want}
        </ProductCard.Image>

        {any ProductCard element or custom element that we want}

        <ProductCard.Details />

        {any ProductCard element or custom element that we want}
    </ProductCard>

    Additionally every component has access to the product card data. The `useProductCardContext` hook can be imported and then used to have access to the data and avoid passing props to the components.

    Check storybook for examples.
*/

import clsx from "clsx";
import React, { RefObject, useEffect, useState } from "react";
import { EventLabel, useSaveForLater } from "web/react/hooks/use-save-for-later/use-save-for-later";
import { FeedPage } from "web/react/pages/feed/utils";

import userProfiler from "web/script/modules/userprofiler";
import { ProductCardSerializer } from "web/types/serializers";
import ProductCardBadges from "./product-card-badges";
import { ProductCardDetails } from "./product-card-details";
import ProductCardImage from "./product-card-image";
import ProductCardSaveForLaterButton from "./product-card-save-for-later";
import ProductCardStaffTools from "./product-card-staff-tools";
import * as styles from "./product-card.css";

export interface ProductCardProps {
    product: ProductCardSerializer;
    children: React.ReactNode;
    className?: string;
    dataTestId?: string;
    productCardRef?: RefObject<HTMLDivElement> | null;
    feedType?: FeedPage | string;
}

const ProductCardContext = React.createContext<ProductCardSerializer | undefined>(undefined);

export function useProductCardContext(): ProductCardSerializer {
    const context = React.useContext(ProductCardContext);

    if (!context) {
        throw new Error(`useProductCard must be used within a ProductCardProvider`);
    }

    return context;
}

/**
 * Design System
 * @name ProductCard
 * @version 1.1
 * @design https://www.figma.com/file/VhOHx4tv9uYXZHXAAKPVYf/Design-System?node-id=6110%3A66410
 */
function ProductCard({
    product,
    className,
    dataTestId,
    children,
    productCardRef,
    feedType,
}: ProductCardProps): React.ReactElement {
    const { isSaved, loading, isSaving } = useSaveForLater(product.id.toString(), EventLabel.FEED);
    //  todo: we may want to pass values from the useSaveForLater hook into the ProductCardContext context
    const [showOpacity, setShowOpacity] = useState(false);

    useEffect(() => {
        // Check if isSaved is updated to false & fade product out of feed
        if (isSaved === false && !loading) {
            setShowOpacity(true);
        }
    }, [isSaved, isSaving, loading]);

    return (
        <ProductCardContext.Provider value={product}>
            <div
                ref={productCardRef}
                id={product.slug}
                className={clsx(styles.productCard, className, styles.transitionRule, {
                    [styles.opacityRule]: feedType === FeedPage.WISH_LIST && showOpacity,
                })}
                data-testid={dataTestId}
            >
                {children}
                {userProfiler.isStaffUser() ? (
                    <ProductCardStaffTools linkId={product.link_id} />
                ) : null}
            </div>
        </ProductCardContext.Provider>
    );
}

// LEGACY, please use components inside /emo/ subfolder
// when using the ProductCard component from now on
ProductCard.Badges = ProductCardBadges;
ProductCard.Image = ProductCardImage;
ProductCard.SaveForLaterButton = ProductCardSaveForLaterButton;
ProductCard.Details = ProductCardDetails;

export default ProductCard;
