import React from "react";
import SVGIcon from "web/react/components/svg-icon/svg-icon";
import * as styles from "./product-card-staff-tools.css";

interface ProductCardStaffToolsProps {
    linkId: number;
}

function ProductCardStaffTools({ linkId }: ProductCardStaffToolsProps): React.ReactElement {
    function copyLinkId(): void {
        navigator.clipboard.writeText(linkId);
    }

    let lystOsLink = `https://os.lystit.com/product-data/product/?link_id=${linkId}`;

    return (
        <div className={styles.iconContainer}>
            <a href={lystOsLink} className={styles.icon} target="_blank" rel="noreferrer">
                <SVGIcon title="Show product in LystOS" name="info-icon" />
            </a>
            <div onClick={copyLinkId}>
                <SVGIcon title="Copy buy option id" className={styles.icon} name="copy" />
            </div>
        </div>
    );
}

export default ProductCardStaffTools;
