import clsx from "clsx";
import React, { useEffect, useState } from "react";
import LoadingSpinner from "web/react/components/loading-spinner/loading-spinner";
import SVGIcon from "web/react/components/svg-icon/svg-icon";
import TrackedButton from "web/react/components/tracked-button/tracked-button";
import { Heading } from "web/react/emo/heading";
import { Text } from "web/react/emo/text";
import analytics from "web/script/analytics/analytics";
import { gettext, gettextNoop } from "web/script/modules/django-i18n";
import requester from "web/script/modules/requester";
import logging from "web/script/utils/logging";
import { AccountSettingsDataSerializer } from "web/types/serializers";
import * as styles from "./account-settings.css";

gettextNoop("settings.account.country.update_successful");
gettextNoop("settings.account.country.update_failed");
gettextNoop("settings.account.gender.update_successful");
gettextNoop("settings.account.gender.update_failed");
gettextNoop("settings.account.subscriptions.update_successful");
gettextNoop("settings.account.subscriptions.update_failed");

async function request(data: any): Promise<any> {
    const response = await requester("/account/", { body: data, method: "POST" });
    const json = await requester.toJSON(response);
    return json;
}

interface SwitchContainerProps {
    name: string;
    type: "radio" | "select";
    value?: any;
    options: [string, string][];
    localizationKey: string;
    eventCategory: string;
    eventAction: string;
    className: string;
}

function SwitchContainer({
    name,
    type,
    value,
    options,
    localizationKey,
    eventCategory,
    eventAction,
    className,
}: SwitchContainerProps): React.ReactElement {
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState<"success" | "error" | undefined>();
    const [localValue, setLocalValue] = useState(value);

    function cleanup(): void {
        setTimeout(() => {
            setStatus(undefined);
        }, 2500);
    }

    async function handleChange(
        e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ): Promise<void> {
        const prevValue = localValue;
        setLocalValue(e.target.value);

        if (loading) {
            return;
        }

        setLoading(true);
        analytics.event(eventCategory, eventAction);

        try {
            await request({ [name]: e.target.value });
            analytics.event(eventCategory, eventAction, "Successfully", true);
            setStatus("success");
        } catch (e: unknown) {
            analytics.event(eventCategory, eventAction, "Error", true);
            logging.error(`Could not update ${name} subscription settings`, e as string);
            setLocalValue(prevValue);
            setStatus("error");
        } finally {
            setLoading(false);
            cleanup();
        }
    }

    return (
        <div className={clsx(styles.switchWrapper, `${styles.switchWrapper}-${className}`)}>
            <div className={clsx(styles.switchContainer, `${styles.switchContainer}-${className}`)}>
                {type === "radio" &&
                    options.map(([optionValue, optionLabel]) => (
                        <label
                            key={optionValue}
                            className={clsx(styles.choice, `${styles.choice}-${className}`)}
                        >
                            <input
                                className={clsx(styles.input, `${styles.input}-${className}`)}
                                type="radio"
                                name={name}
                                autoComplete="off"
                                value={optionValue}
                                checked={localValue === optionValue}
                                onChange={handleChange}
                                disabled={loading || !!status}
                            />

                            <div className={clsx(styles.button, `${styles.button}-${className}`)}>
                                {optionLabel}
                            </div>
                        </label>
                    ))}

                {type === "select" && (
                    <div className={styles.selectWrapper}>
                        <select
                            className={styles.select}
                            onChange={handleChange}
                            disabled={loading || !!status}
                            value={localValue}
                        >
                            {options.map(([optionValue, optionLabel]) => (
                                <option key={optionValue} value={optionValue}>
                                    {optionLabel}
                                </option>
                            ))}
                        </select>
                    </div>
                )}
            </div>

            {(loading || status) && (
                <div
                    className={clsx(styles.fieldStatus, `${styles.fieldStatus}-${className}`, {
                        [styles.fieldStatusSuccess]: status === "success",
                        [styles.fieldStatusError]: status === "error",
                    })}
                >
                    {loading && (
                        <>
                            <LoadingSpinner inline />
                            <Text as="span" textStyle="callout-small">
                                {gettext("settings.account.updating")}
                            </Text>
                        </>
                    )}

                    {status === "success" && (
                        <>
                            <SVGIcon name="tick" className={styles.icon} />
                            <Text as="span" textStyle="callout-small">
                                {gettext(`settings.account.${localizationKey}.update_successful`)}
                            </Text>
                        </>
                    )}

                    {status === "error" && (
                        <>
                            <SVGIcon name="cross" className={styles.icon} />
                            <Text as="span" textStyle="callout-small">
                                {gettext(`settings.account.${localizationKey}.update_failed`)}
                            </Text>
                        </>
                    )}
                </div>
            )}
        </div>
    );
}

interface CountrySwitchContainerProps {
    value?: AccountSettingsDataSerializer["form"]["country"];
}

function CountrySwitchContainer({ value }: CountrySwitchContainerProps): React.ReactElement {
    const [allCountries, setAllCountries] = useState([]);

    useEffect(() => {
        (async () => {
            if (!allCountries.length) {
                const data = await requester.get("/account/get_countries/");
                setAllCountries(data.all_countries);
            }
        })();
    }, [allCountries]);

    return (
        <div className={styles.container}>
            <div className={styles.field}>
                <SwitchContainer
                    name="country"
                    type="select"
                    value={value}
                    options={allCountries}
                    localizationKey="country"
                    eventCategory="Country"
                    eventAction="Changed"
                    className="country"
                />
            </div>

            <Text textStyle="body-2-v2" className={styles.guide} as="p">
                {/* This will tailor your experience, showing you the type of
                                products most suited to you. */}
                {gettext("settings.account.country.description")}
            </Text>
        </div>
    );
}

interface GenderSwitchContainerProps {
    value?: "M" | "F";
}

function GenderSwitchContainer({ value }: GenderSwitchContainerProps): React.ReactElement {
    return (
        <div className={styles.container}>
            <div className={styles.field}>
                <SwitchContainer
                    name="gender"
                    type="radio"
                    value={value}
                    options={[
                        ["M", gettext("settings.account.form.gender.menswear")],
                        ["F", gettext("settings.account.form.gender.womenswear")],
                    ]}
                    localizationKey="gender"
                    eventCategory="Gender"
                    eventAction="Changed"
                    className="gender"
                />
            </div>

            <Text textStyle="body-2-v2" className={styles.guide} as="p">
                {/* This will tailor your experience, showing you the type of products
                            most suited to you. */}
                {gettext("settings.account.gender.description")}
            </Text>
        </div>
    );
}

interface SubscriptionSwitchContainerProps {
    title: string;
    description: string;
    name: string;
    value?: "True" | "False";
}

function SubscriptionSwitchContainer({
    title,
    description,
    name,
    value,
}: SubscriptionSwitchContainerProps): React.ReactElement {
    return (
        <div className={styles.subscription}>
            <Text textStyle="body-2-v2" className={styles.smalltitle}>
                {title}
            </Text>

            <SwitchContainer
                name={name}
                type="radio"
                value={value}
                options={[
                    ["True", gettext("settings.account.form.subscriptions.on")],
                    ["False", gettext("settings.account.form.subscriptions.off")],
                ]}
                localizationKey="subscriptions"
                eventCategory="Subscription Settings"
                eventAction={`Changed - ${name}`}
                className="subscription"
            />

            <Text as="p" textStyle="body-2-v2" className={styles.subscriptionDescription}>
                {description}
            </Text>
        </div>
    );
}

export default function AccountSettings({
    form,
    urls,
    is_country_targeted_market: isCountryTargetedMarket,
    email,
}: AccountSettingsDataSerializer): React.ReactElement {
    return (
        <div className={styles.content}>
            <Heading as="h2" textStyle="large-title" className={styles.heading}>
                {gettext("settings.account.title")}
            </Heading>

            <form method="post" encType="multipart/form-data">
                {/* Lyst.com only - do not localise */}
                {!isCountryTargetedMarket && (
                    <div className={clsx(styles.section, styles.sectionExtended)}>
                        <Heading as="h3" textStyle="subhead" className={styles.title}>
                            {"Which country do you live in?"}
                        </Heading>

                        <CountrySwitchContainer value={form.country} />
                    </div>
                )}

                <div className={clsx(styles.section, styles.sectionExtended)}>
                    <Heading as="h3" textStyle="subhead" className={styles.title}>
                        {gettext("settings.account.gender.title")}
                    </Heading>

                    <GenderSwitchContainer value={form.gender} />
                </div>

                <div className={styles.section}>
                    <Heading as="h3" textStyle="subhead" className={styles.title}>
                        {gettext("settings.account.subscriptions.title")}
                    </Heading>

                    <Text as="p" textStyle="body-2-v2" className={styles.text}>
                        <span
                            dangerouslySetInnerHTML={{
                                __html: gettext(
                                    "settings.account.subscriptions.receiving_emails_to",
                                    { email }
                                ),
                            }}
                        />
                    </Text>

                    <SubscriptionSwitchContainer
                        title={gettext("settings.account.form.subscription.newsletters.label")}
                        description={gettext(
                            "settings.account.subscriptions.newsletters.description"
                        )}
                        name="newsletters_email"
                        value={form.newsletters_email}
                    />

                    <SubscriptionSwitchContainer
                        title={gettext("settings.account.form.subscription.promotions.label")}
                        description={gettext(
                            "settings.account.subscriptions.promotions.description"
                        )}
                        name="promotions_email"
                        value={form.promotions_email}
                    />

                    <SubscriptionSwitchContainer
                        title={gettext("settings.account.form.subscription.notifications.label")}
                        description={gettext(
                            "settings.account.subscriptions.notifications.description"
                        )}
                        name="notifications_email"
                        value={form.notifications_email}
                    />
                </div>

                <div className={styles.section}>
                    <Heading as="h3" textStyle="subhead" className={styles.title}>
                        {gettext("account.authentication.password")}
                    </Heading>

                    <Text as="p" textStyle="body-2-v2" className={styles.link}>
                        <TrackedButton
                            eventCategory="Password Change Url"
                            eventAction="clicked"
                            eventLabel=""
                            href={urls.change_password_url as string}
                        >
                            {gettext("settings.account.password_change")}
                        </TrackedButton>
                    </Text>
                </div>

                <div className={styles.section}>
                    <Heading as="h3" textStyle="subhead" className={styles.title}>
                        {gettext("settings.account.help.title")}
                    </Heading>

                    <Text as="p" textStyle="body-2-v2" className={styles.link}>
                        <TrackedButton
                            eventCategory="Account FAQ Url"
                            eventAction="clicked"
                            eventLabel=""
                            href={urls.account_faq as string}
                            openInNewTab
                        >
                            {gettext("settings.account.help.account_faq")}
                        </TrackedButton>
                    </Text>

                    <Text as="p" textStyle="body-2-v2" className={styles.link}>
                        <TrackedButton
                            eventCategory="Help Centre Url"
                            eventAction="clicked"
                            eventLabel=""
                            href={urls.homepage as string}
                            openInNewTab
                        >
                            {gettext("settings.account.help.help_centre")}
                        </TrackedButton>
                    </Text>

                    <Text as="p" textStyle="body-2-v2" className={styles.link}>
                        <TrackedButton
                            eventCategory="Contact Us Url"
                            eventAction="clicked"
                            eventLabel=""
                            href={urls.contact_form as string}
                            openInNewTab
                        >
                            {gettext("settings.account.help.contact_us")}
                        </TrackedButton>
                    </Text>
                </div>
            </form>
        </div>
    );
}
